<template>
  <div class="article-detail-page" v-loading="loading">
    <article-detail :data="data" v-if="data"></article-detail>
    <div class="back">
      <fm-btn @click="back">返回</fm-btn>
    </div>
  </div>
</template>

<script>
import ArticleDetail from './cmp/detail'

import {
  articleRequest
} from '../../api'

export default {
  components: {
    ArticleDetail
  },
  data () {
    return {
      dataId: null,
      type: null,
      data: null,
      loading: false
    }
  },
  mounted () {
    this.data = null
    this.dataId = this.$router.history.current.query.id
    this.type = this.$router.history.current.query.type
    this.loadData()
  },
  methods: {
    back () {
      if (this.type === 'manage') {
        this.$router.push({
          name: 'hrms.article.manage'
        })
      } else if (this.type === 'my') {
        this.$router.push({
          name: 'hrms.article.my'
        })
      } else {
        this.$router.go(-1)
      }
    },
    async loadData () {
      if (!this.dataId || !this.type) {
        return
      }
      this.loading = true
      let datas = []
      if (this.type === 'manage') {
        datas = await articleRequest.get({
          id: this.dataId
        })
      } else {
        datas = await articleRequest.getByAuth({
          id: this.dataId
        })
      }
      this.data = datas && datas.length > 0 ? datas[0] : null
      this.loading = false
    }
  }
}
</script>

<style scoped lang="less">
.article-detail-page {
  border-radius: 8px;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: #fff;
  position: relative;
  .back {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
