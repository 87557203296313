<style src="@wangeditor/editor/dist/css/style.css"></style>

<template>
  <div class="content article-detail">
    <div class="title">
      {{articleData.title}}
    </div>
    <div class="base-info">
      <div class="info-item">
        <div class="label">编辑人：</div>
        <div class="value">{{articleData.editUserName}}</div>
      </div>
      <div class="info-item">
        <div class="label">上架时间：</div>
        <div class="value">{{articleData.status === 'up' ? (articleData.pushTime ? articleData.pushTime.slice(0, 10) : '已上架无上架时间') : '未展示'}}</div>
      </div>
    </div>
    <div class="other-info">
      <img :src="src" v-if="src" style="width: 100%"/>
    </div>
    <div class="content">
      <div class="label">正文：</div>
      <Editor read-only :toolbar="false" :content="articleData.content" />
    </div>
    <div class="files">
      <div class="label">附件：</div>
      <file-manage
          preview
          :file-data-ids="fileIds"
          :funs="{get: true, upload: false, del: false}">
        </file-manage>
    </div>
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

import FileManage from '@/components/base/FileManage'
import Editor from '@/components/editor'

export default {
  components: {
    FileManage, Editor
  },
  props: {
    data: {
      type: Object
    }
  },
  data () {
    return {
      articleData: {},
      fileIds: [],
      otherInfo: {},
      src: null
    }
  },
  watch: {
    data: {
      handler () {
        let data = JSON.parse(JSON.stringify(this.data))
        this.otherInfo = data.otherInfo ? JSON.parse(data.otherInfo) : {}
        this.fileIds = data.fileIds ? data.fileIds.split(',').map(v => Number(v)) : []
        this.articleData = data
        this.loadFile()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async loadFile () {
      let fileId = this.articleData && this.articleData.coverFileId ? this.articleData.coverFileId : null
      this.src = null
      if (!fileId) {
        return
      }
      try {
        let fileData = await fileRequest.download({
          id: fileId
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this.src = src
        } else {
          this.src = null
        }
      } catch (error) {
        this.src = null
      }
    }
  }
}
</script>

<style scoped lang="less">
.article-detail {
  padding: 20px 200px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .title {
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  .label {
    min-width: 80px;
    color: #666;
  }
  .info-item {
    display: flex;
    margin-bottom: 5px;
  }
  .content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
